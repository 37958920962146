import React, {useState,useContext} from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddAPhotoIcon from '@material-ui/icons/AddAPhotoTwoTone';
import LandscapeIcon from '@material-ui/icons/LandscapeOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/SaveTwoTone';
import {CREATE_PIN_MUTATION} from './../../graphql/mutations'
import Context from './../../context'
import { useClient } from '../../client'
import { unstable_useMediaQuery as useMediaQuery  } from "@material-ui/core/useMediaQuery";

const CreatePin = ({ classes }) => {
  const[title,setTitle] = useState("")
  const[image,setImage] = useState("")
  const[content,setContent] = useState("")
  const client = useClient()
  const[submitting, setSubmitting] = useState(false)
  const mobileSize = useMediaQuery("(max-width: 650px)")

  const {state, dispatch} = useContext(Context)
  
  const handleUploadImage = async () => {
    const data = new FormData()
    data.append('file',image)
    data.append("upload_preset","geopin")
    data.append("cloud_name","ashokhein")
    const res = await axios.post(
      "https://api.cloudinary.com/v1_1/ashokhein/image/upload",
      data
    )
    return res.data.url
  }

  const handleFormSubmit = async (event) => {
    try {
      event.preventDefault()
      setSubmitting(true)
      const url = await handleUploadImage()
      const { latitude, longitude } = state.draftPin
      const variables = {
        content,
        title,
        image: url,
        latitude,
        longitude
      }
      await client.request(CREATE_PIN_MUTATION,variables)
      handleDeleteDraft()
    } catch(err) {
      setSubmitting(false)
      console.error("PIN CREATION ERROR",err)
    }
  }

  const handleDeleteDraft = () => {
    setTitle("")
    setImage("")
    setContent("")
    dispatch({ type: "DELETE_DRAFT_LOCATION"})
  }

	return (
		<form className={classes.form}>
			<Typography className={classes.alignCenter} component="h2" variant="h4" color="secondary">
				<LandscapeIcon className={classes.iconLarge} /> Pin Location
			</Typography>
			<div>
				<TextField name="title" label="Title" placeholder="Insert Pin Title" onChange={e => setTitle(e.target.value)}/>
				<input accept="image/*" id="image" type="file" className={classes.input} onChange={e => setImage(e.target.files[0])}/>
				<label htmlFor="image">
					<Button className={classes.button} component="span" size="small" style={{color: image && "green"}}>
						<AddAPhotoIcon />
					</Button>
				</label>
			</div>
      <div className={classes.contentField}>
        <TextField name="content" label="Content" multiline margin="normal" fullWidth variant="outlined" rows={mobileSize ? 3 : 6} onChange={e => setContent(e.target.value)} />
      </div>
      <div>
        <Button className={classes.button} variant="contained" color="primary" onClick={handleDeleteDraft}>
            Discard
            <ClearIcon className={classes.leftIcon}/>
        </Button>
        <Button className={classes.button} variant="contained" color="secondary" disabled={!title.trim() || !image || !content.trim() || submitting} onClick={handleFormSubmit}>
            Submit
            <SaveIcon className={classes.rightIcon}/>
        </Button>        
      </div>
 		</form>
	);
};

const styles = (theme) => ({
	form: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		paddingBottom: theme.spacing.unit
	},
	contentField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: '95%'
	},
	input: {
		display: 'none'
	},
	alignCenter: {
		display: 'flex',
		alignItems: 'center'
	},
	iconLarge: {
		fontSize: 40,
		marginRight: theme.spacing.unit
	},
	leftIcon: {
		fontSize: 20,
		marginRight: theme.spacing.unit
	},
	rightIcon: {
		fontSize: 20,
		marginLeft: theme.spacing.unit
	},
	button: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit,
		marginLeft: 0
	}
});

export default withStyles(styles)(CreatePin);
